import React, { useEffect, useCallback, useState } from 'react'
import { StepContentComponent } from './AddResourceSteps'
import { ResourceType, Filler, Cement } from '../../types'
import { StepContent, Table, TableHead, TableRow, TableCell, TableBody } from '@material-ui/core'
import NumericTextField from '../NumericTextField'
import useAuthorized from '../../useAuthorized'
import {getAll} from '../../HTTPClients/RecipeApp/resources/resources';

const FillerPropertiesStep: StepContentComponent<Filler> = ({ onChange, onSave, resource, ...stepContentProps }) => {
  const disabled = !useAuthorized(['update:resources_filler'])
  const [cements, setCement] = useState([] as Cement[])

  useEffect(() => {
    getAll(true).then(
        function(response) {
          setCement(response.data.data.filter((r: any) => r.type===ResourceType.Cement))
        }
    )
  },[setCement])

  useEffect(() => {
    const missing = cements.reduce((missing, cement) => {
      if (typeof resource.cementKFactors === 'undefined') {
        return [...missing, cement]
      } else if (resource.cementKFactors.map(c => c.cement.id).indexOf(cement.id) < 0) {
        return [...missing, cement]
      } else {
        return missing
      }
    }, [] as Cement[])
    if (missing.length > 0) {
      onChange({ ...resource, cementKFactors: [...resource.cementKFactors || [], ...missing.map(m => ({ cement: m, kFactor: 0 }))] })
    }
  }, [cements, resource, onChange]);

  const handleChange = useCallback((cement: Cement, kFactor: number) => {
    const index = resource.cementKFactors.findIndex(item => item.cement.id === cement.id)
    resource.cementKFactors[index].kFactor = kFactor
    onChange({ ...resource, cementKFactors: [...resource.cementKFactors] })
  }, [onChange, resource])

  return (
    <StepContent {...stepContentProps}>
      <Table>
        <TableHead>
          <TableRow><TableCell>Cement</TableCell><TableCell>K-Factor</TableCell></TableRow>
        </TableHead>
        <TableBody>
          {resource.cementKFactors && resource.cementKFactors.map(({ cement, kFactor }, k) => (
            <TableRow key={k}>
              <TableCell>{cement.name}</TableCell>
              <TableCell padding="none">
                <NumericTextField
                  margin="dense"
                  value={kFactor}
                  onChange={(e: any) => handleChange(cement, e.target.value)}
                  maximumFractionDigits={2}
                  disabled={disabled}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </StepContent>
  )
}

export default FillerPropertiesStep
