import axiosHttpClient from "../axiosHttpClient";
import {Settings} from "../../../config/settings";

export function post(data: any) {
	return axiosHttpClient.post('/resource/'+Settings.default_plant_id.toString(), data);
}

export async function toggleActive(id: number) {
	return axiosHttpClient.get('/resource/toggle-active/'+id.toString());
}

export async function remove(id: number) {
	return axiosHttpClient.get('/resource/remove/'+id.toString());
}

export async function getAll(active: boolean=null) {
	let param='';
	if(active!==null) {
		param=active ? 'true' : 'false';
		param='/'+param;
	}
	return axiosHttpClient.get('/resources/'+Settings.default_plant_id.toString()+param);
}

export async function getOne(id: number) {
	return axiosHttpClient.get('/resource/one/'+id.toString()+'/'+Settings.default_plant_id.toString());
}

export function deleteAllowed(id: number) {
	return axiosHttpClient.get('/resource/delete-allowed/'+id.toString());
}
