import React, { Fragment, useRef, useEffect, useState, useCallback, ChangeEvent } from 'react';
import StepContent from '@material-ui/core/StepContent';
import { StepContentComponent } from './AddResourceSteps';
import {
  TextField,
  Grid,
  Typography,
  MenuItem,
  TableRow,
  TableCell,
  Table,
  TableBody,
  InputAdornment,
  TableHead,
  TableFooter,
  FormControl,
  RadioGroup, FormLabel, FormControlLabel, Radio
} from '@material-ui/core';
import { Addition, GradingCurve, SieveSet, SieveSetType, SieveTest, SieveTestStep } from '../../types';
import { getCummulativeRestPercentages, getFinenessModulus } from '../../computed/recipeComputations';
import NumberFormat from 'react-number-format';
import GradingCurveChart from '../recipes/GradingCurveChart';
import NumericTextField from '../NumericTextField';
import {getAll as getSieveSets} from "../../HTTPClients/RecipeApp/sieve-tests/sieve-sets";
import {getAll as getGradingCurves} from "../../HTTPClients/RecipeApp/grading-curves";

const numberFormatProps = {
  displayType: 'text' as 'text',
  decimalSeparator: ',',
  thousandSeparator: '.',
  decimalScale: 1
}

export const renderSieveTestOptionalElement = (resource: Addition) => <Fragment>
  {resource.sieveTest && resource.sieveTest.sieveSet ? <Fragment><Typography component="span" variant="caption">Zeefset: </Typography><Typography component="span" variant="body2">{resource.sieveTest.sieveSet.code}</Typography></Fragment> : null}
  {resource.sieveTest && resource.sieveTest.sieveSet ? <span>, </span> : null}
  {resource.sieveTest ? <Fragment><Typography component="span" variant="caption">Fijnheidsmodulus: </Typography><Typography component="span" variant="body2">{0 /* TODO: calculate fineness modulus */}</Typography></Fragment> : null}
</Fragment>;

const SieveTestStepComponent: StepContentComponent<Addition> = ({ onChange, onSave, resource, ...stepContentProps }) => {
  const [sieveSets, setSieveSets] = useState([] as SieveSet[])
  const [gradingCurves, setGradingCurves] = useState([] as GradingCurve[])
  const [inputMethod, setInputMethod] = useState('weight');
  const percentages = resource.sieveTest && getCummulativeRestPercentages(resource.sieveTest);
  const [finenessModulus, setFinenessModulus] = useState(resource && resource.sieveTest ? getFinenessModulus(percentages) : undefined);

  const firstInputRef = useRef<HTMLInputElement>(null);

  if(!sieveSets.length) {
    getSieveSets().then(
        function(response) {
          setSieveSets(response.data.data)
        }
    )
  }
  if(!gradingCurves.length) {
    getGradingCurves().then(
        function(response) {
          setGradingCurves(response.data.data)
        }
    )
  }

  useEffect(() => {
    !resource.id && stepContentProps.active && firstInputRef.current && firstInputRef.current.focus();
  }, [stepContentProps.active, resource.id]);

  const handleInputMethod = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setInputMethod((e.target as HTMLInputElement).value);
  },[]);

  const handleChangeSieveSet = useCallback((e: ChangeEvent<HTMLSelectElement>) => {
    const sieveSet = sieveSets.find((s: SieveSet) => s.id === Number(e.target.value));
    if (sieveSet) {
      const sieveTest: SieveTest = {
        startingWeight: 0,
        sieveSteps: sieveSet.sieveSteps.map(({ sieveSize }) => ({ sieveSize, restWeight: 0 } as any)),
        sieveSet
      } as any
      onChange({ ...resource, sieveTest });
    }
  }, [onChange, resource, sieveSets]);

  const handleChangeSieveRest = useCallback((sieveStep: SieveTestStep, value?: number) => {
    value=value===undefined ? 0 : value;
    if (resource.sieveTest) {
      const index = resource.sieveTest.sieveSteps.findIndex((step: SieveTestStep) => step === sieveStep);
      if (index >= 0) {
        resource.sieveTest.sieveSteps[index].restWeight = value as any;
        resource.sieveTest.sieveSteps[index].fallThrough= 100 - value as any;
        const percentages = getCummulativeRestPercentages(resource.sieveTest);
        const finenessModulus = getFinenessModulus(percentages);
        setFinenessModulus(finenessModulus);
        onChange({ ...resource, sieveTest: { ...resource.sieveTest, finenessModulus } });
      }
    }
  }, [onChange, resource])

  const handleChangeSieveFallThrough = useCallback((sieveStep: SieveTestStep, value?: number) => {
    value=value===undefined ? 0 : value;
    if(resource.sieveTest) {
      const index = resource.sieveTest.sieveSteps.findIndex((step: SieveTestStep) => step === sieveStep);
      if(index >= 0) {
        let restWeight = resource.sieveTest.startingWeight - (resource.sieveTest.startingWeight * (value/100));
//          (index > 0 ? resource.sieveTest.sieveSteps[index-1].restWeight : 0);
        console.log('value='+value+' - index='+index+' - restWeight='+restWeight);
//        restWeight-=restWeight * (value/100);
        console.log('new restweight='+restWeight);

        resource.sieveTest.sieveSteps[index].fallThrough=value;
        resource.sieveTest.sieveSteps[index].restWeight=resource.sieveTest.startingWeight * (value/100);
        if(value)
          resource.sieveTest.sieveSteps[index].restWeight=restWeight;
        const percentages = getCummulativeRestPercentages(resource.sieveTest);
        const finenessModulus = getFinenessModulus(percentages);
        setFinenessModulus(finenessModulus);
        onChange({ ...resource, sieveTest: { ...resource.sieveTest, finenessModulus } });
      }
    }
  }, [onChange, resource])

  return (
    <StepContent {...stepContentProps}>
      <Grid container={true} spacing={2}>
        <Grid item={true} xs={12}>
          <TextField
            label="Zeefset"
            value={(resource.sieveTest && resource.sieveTest.sieveSet && resource.sieveTest.sieveSet.id) || ''}
            onChange={handleChangeSieveSet as any}
            variant="outlined"
            fullWidth={true}
            required={true}
            select={true}
          >
            <MenuItem>Geen</MenuItem>
            {sieveSets.filter((s: SieveSet) => resource.isSand ? s.type === SieveSetType.Sand : s.type === SieveSetType.Gravel).map((sieveSet: SieveSet, k: number) => <MenuItem key={k} value={sieveSet.id}>
              {sieveSet.code}
            </MenuItem>)}
          </TextField>
        </Grid>
        {resource.sieveTest && (<Fragment>
          <Grid item={true} xs={12}>
            <NumericTextField
              label="Startgewicht"
              value={resource.sieveTest.startingWeight}
              onChange={(e: any) => onChange({ ...resource, sieveTest: { ...resource.sieveTest as SieveTest, startingWeight: e.target.value } })}
              fullWidth={true}
              required={true}
              maximumFractionDigits={2}
              InputProps={{
                endAdornment: <InputAdornment position="end">g</InputAdornment>
              }}
            />
          </Grid>
          <Grid item={true} xs={12}>
            <FormControl>
              <FormLabel id="demo-row-radio-buttons-group-label">Invoermethode</FormLabel>
              <RadioGroup row aria-labelledby="demo-row-radio-buttons-group-label" name="row-radio-buttons-group"
                value={inputMethod}
                onChange={handleInputMethod}
              >
                <FormControlLabel value="weight" control={<Radio />} label="Zeefrest gewichten" />
                <FormControlLabel value="percentage" control={<Radio />} label="Doorval percentages" />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item={true} xs={12}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Zeefmaat</TableCell>
                  {inputMethod==='percentage' && <TableCell>Doorval</TableCell>}
                  <TableCell>Zeefrest</TableCell>
                  <TableCell align="right">Cum. zeefrest</TableCell>
                  {inputMethod==='weight' && <TableCell>Doorval</TableCell>}
                </TableRow>
              </TableHead>
              <TableBody>
                {resource.sieveTest.sieveSteps.sort((a: SieveTestStep, b: SieveTestStep) => b.sieveSize.size - a.sieveSize.size).map((step: SieveTestStep, k: number) =>
                  {
                    const cummulative = percentages && percentages.find(p => p.sieveSize.size === step.sieveSize.size);
                    const fallThrough=cummulative.percentage>=0 && cummulative.percentage<=100 ? 100-cummulative.percentage : 0;
                    if(step.fallThrough===undefined)
                      step.fallThrough=100 - cummulative.percentage;

                  return (
                    <TableRow key={k}>
                      <TableCell>{step.sieveSize.code}</TableCell>
                      {inputMethod==='weight' && <TableCell padding="none">
                        <NumericTextField
                          placeholder="Rest gewicht"
                          value={step.restWeight}
                          onChange={(e: any) => handleChangeSieveRest(step, e.target.value)}
                          margin="dense"
                          fullWidth={true}
                          required={true}
                          maximumFractionDigits={2}
                          InputProps={{
                            endAdornment: <InputAdornment position="end">g</InputAdornment>
                          }}
                        />
                      </TableCell>}
                      {inputMethod==='percentage' && <TableCell padding="none">
                        <NumericTextField
                          placeholder="Doorval"
                          value={step.fallThrough}
                          onChange={(e: any) => handleChangeSieveFallThrough(step, e.target.value)}
                          margin="dense"
                          fullWidth={true}
                          required={true}
                          maximumFractionDigits={2}
                          InputProps={{
                            endAdornment: <InputAdornment position="end">%</InputAdornment>
                          }}
                        />
                      </TableCell>}
                      {inputMethod==='percentage' && <TableCell align="right">{<Fragment><NumberFormat value={step.restWeight} {...numberFormatProps} />g</Fragment>}</TableCell>}
                      <TableCell align="right">{cummulative && <Fragment><NumberFormat value={cummulative.percentage} {...numberFormatProps} />%</Fragment>}</TableCell>
                      {inputMethod==='weight' && <TableCell align="right">{<Fragment><NumberFormat value={fallThrough} {...numberFormatProps} />%</Fragment>}</TableCell>}
                    </TableRow>
                  )
                })}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TableCell>Fijnheidsmodulus</TableCell>
                  <TableCell />
                  <TableCell align="right"><NumberFormat value={finenessModulus} {...numberFormatProps} decimalScale={2} /></TableCell>
                </TableRow>
              </TableFooter>
            </Table>
          </Grid>
          {percentages && <GradingCurveChart restPercentages={percentages} gradingCurve={resource.gradingCurve || undefined} width={510} height={300} />}
        </Fragment>)}
        <Grid item={true} xs={12}>
          <TextField
            label="Zeeflijn"
            value={(resource.gradingCurve && resource.gradingCurve.id) || ''}
            onChange={e => onChange({ ...resource, gradingCurve: gradingCurves.find(gc => gc.id === Number(e.target.value)) as GradingCurve })}
            variant="outlined"
            fullWidth={true}
            select={true}
          >
            <MenuItem>Geen</MenuItem>
            {gradingCurves.filter(s => resource.isSand ? s.type === SieveSetType.Sand : s.type === SieveSetType.Gravel).map((gradingCurve, k) => <MenuItem key={k} value={gradingCurve.id}>
              {gradingCurve.description}
            </MenuItem>)}
          </TextField>
        </Grid>
      </Grid>
    </StepContent>
  )
}

export default SieveTestStepComponent;
