import React, { Fragment, useRef, useEffect } from 'react';
import StepContent from '@material-ui/core/StepContent';
import { StepContentComponent } from './AddResourceSteps';
import { Grid, InputAdornment, Typography, FormControlLabel, Switch } from '@material-ui/core';
import { Addition } from '../../types';
import NumberFormat from 'react-number-format';
import NumericTextField from '../NumericTextField';

const numberFormatProps = {
  displayType: 'text' as 'text',
  decimalSeparator: ',',
  thousandSeparator: '.',
  decimalScale: 1
}

export const renderAdditionPropertiesOptionalElement = (resource: Addition) => <Fragment>
  {<Fragment><Typography component="span" variant="caption">Type: </Typography><Typography component="span" variant="body2">{resource.isSand ? 'Zand' : 'Grind'}</Typography></Fragment>}
  {(resource.moisture || resource.absorption) ? <span>, </span> : null}
  {resource.moisture ? <Fragment><Typography component="span" variant="caption">Vochtgehalte (incl. absorptie): </Typography><Typography component="span" variant="body2"><NumberFormat value={resource.moisture} {...numberFormatProps} /> %</Typography></Fragment> : null}
  {resource.moisture && resource.absorption ? <span>, </span> : null}
  {resource.absorption ? <Fragment><Typography component="span" variant="caption">Absorptie: </Typography><Typography component="span" variant="body2"><NumberFormat value={resource.absorption} {...numberFormatProps} /> %</Typography></Fragment> : null}
</Fragment>;

const AdditionPropertiesStep: StepContentComponent<Addition> = ({ onChange, onSave, resource, ...stepContentProps }) => {
  const firstInputRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    !resource.id && stepContentProps.active && firstInputRef.current && firstInputRef.current.focus();
  }, [stepContentProps.active, resource.id]);
  return (
    <StepContent {...stepContentProps}>
      <Grid container={true} spacing={2}>
        {!resource.id && <Grid item={true} xs={12}>
          <FormControlLabel
            control={
              <Switch
                checked={resource.isSand || false}
                onChange={e => onChange({ ...resource, isSand: e.target.checked })}
                color="primary"
                value="isSand"
              />
            }
            label="Dit is een zand"
          />
        </Grid>}
        <Grid item={true} lg={6}>
          <NumericTextField
            label="Vochtgehalte (incl. absorptie)"
            value={typeof resource.moisture !== 'undefined' && resource.moisture !== null ? resource.moisture : ''}
            onChange={(e: any) => onChange({ ...resource, moisture: e.target.value })}
            fullWidth={true}
            inputRef={firstInputRef}
            maximumFractionDigits={1}
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>
            }}
          />
        </Grid>
        <Grid item={true} lg={6}>
          <NumericTextField
            label="Absorptie"
            value={typeof resource.absorption !== 'undefined' && resource.absorption !== null ? resource.absorption : ''}
            onChange={(e: any) => onChange({ ...resource, absorption: e.target.value })}
            fullWidth={true}
            maximumFractionDigits={1}
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
            }}
          />
        </Grid>
      </Grid>
    </StepContent>
  )
}

export default AdditionPropertiesStep;
