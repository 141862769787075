import React from 'react'
import { format } from 'react-numberinput-formatter';
import { TableRow, TableCell } from '@material-ui/core';

type ResourceRevisionsProps = {
  resourceRevisions: any[],
  onlyDiff?: boolean
}

const ResourceRevisionsTable: React.FC<ResourceRevisionsProps> = ({ resourceRevisions, onlyDiff}) => {
  if(onlyDiff) {
    let rr=[];
    for(let resourceRevision of resourceRevisions)
      for(let ra=0; ra<resourceRevision.revisionAmounts.length-1; ra++)
        if(resourceRevision.revisionAmounts[ra].amount!==resourceRevision.revisionAmounts[ra+1].amount) {
          rr.push(resourceRevision);
          break;
        }
    resourceRevisions=rr;
  }

  return <React.Fragment>
    {resourceRevisions.map( (resource) => (
        <TableRow key={resource.id}>
          <TableCell>{resource.name}</TableCell>
          <RevisionAmounts revisionAmounts={resource.revisionAmounts}></RevisionAmounts>
        </TableRow>
    ))}
  </React.Fragment>
}

type RevisionAmountsProps = {
  revisionAmounts: any[]
}

const RevisionAmounts: React.FC<RevisionAmountsProps> = ({ revisionAmounts}) => {
  return <React.Fragment>
    {revisionAmounts.map( (ingredient) => (
      <TableCell key={ingredient.id}>{format(ingredient.amount, {maximumFractionDigits: 2})} kg</TableCell>
    ))}
  </React.Fragment>
}
export default ResourceRevisionsTable
