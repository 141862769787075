import React, { Fragment, useRef, useEffect } from 'react';
import StepContent from '@material-ui/core/StepContent';
import { StepContentComponent } from './AddResourceSteps';
import { Grid, InputAdornment, Typography } from '@material-ui/core';
import { Extra } from '../../types';
import NumberFormat from 'react-number-format';
import NumericTextField from '../NumericTextField';
import useAuthorized from '../../useAuthorized';

const numberFormatProps = {
  displayType: 'text' as 'text',
  decimalSeparator: ',',
  thousandSeparator: '.',
  decimalScale: 1
}

export const renderExtraPropertiesOptionalElement = (resource: Extra) => <Fragment>
  {resource.percentageFine ? <Fragment><Typography component="span" variant="caption">Percentage fijn: </Typography><Typography component="span" variant="body2"><NumberFormat value={resource.percentageFine} {...numberFormatProps} /> %</Typography></Fragment> : null}
</Fragment>;

const ExtraPropertiesStep: StepContentComponent<Extra> = ({ onChange, onSave, resource, ...stepContentProps }) => {
  const disabled = !useAuthorized(['update:resources_extra'])
  const firstInputRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    !resource.id && stepContentProps.active && firstInputRef.current && firstInputRef.current.focus();
  }, [stepContentProps.active, resource.id]);
  return (
    <StepContent {...stepContentProps}>
      <Grid container={true} spacing={2}>
        <Grid item={true} xs={12}>
          <NumericTextField
            label="Percentage fijn"
            value={typeof resource.percentageFine !== 'undefined' && resource.percentageFine !== null ? resource.percentageFine : ''}
            onChange={(e: any) => onChange({ ...resource, percentageFine: e.target.value })}
            fullWidth={true}
            inputRef={firstInputRef}
            maximumFractionDigits={3}
            disabled={disabled}
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>
            }}
          />
        </Grid>
        <Grid item={true} lg={6}>
          <NumericTextField
            label="Vochtgehalte (Incl. absorptie)"
            value={typeof resource.moisture !== 'undefined' && resource.moisture !== null ? resource.moisture : ''}
            onChange={(e: any) => onChange({ ...resource, moisture: e.target.value })}
            fullWidth={true}
            inputRef={firstInputRef}
            maximumFractionDigits={1}
            disabled={disabled}
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>
            }}
          />
        </Grid>
        <Grid item={true} lg={6}>
          <NumericTextField
            label="Absorptie"
            value={typeof resource.absorption !== 'undefined' && resource.absorption !== null ? resource.absorption : ''}
            onChange={(e: any) => onChange({ ...resource, absorption: e.target.value })}
            fullWidth={true}
            maximumFractionDigits={1}
            disabled={disabled}
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>
            }}
          />
        </Grid>
      </Grid>
    </StepContent>
  )
}

export default ExtraPropertiesStep;
