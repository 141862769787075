import React, { useEffect, useRef } from 'react';
import StepContent from '@material-ui/core/StepContent';
import { StepContentComponent } from './AddResourceSteps';
import { TextField } from '@material-ui/core';
import { ResourceUnion } from '../../types';

const ResourceNameStep: StepContentComponent<ResourceUnion> = ({ onChange, onSave, resource, ...stepContentProps }) => {
  const firstInputRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    firstInputRef.current && firstInputRef.current.focus();
  }, []);
  return (
    <StepContent {...stepContentProps}>
      <TextField
        label="Naam van de grondstof"
        value={resource.name || ''}
        onChange={e => onChange({ ...resource, name: e.target.value })}
        onBlur={e=> resource.articleCode=resource.articleCode ? resource.articleCode : e.target.value}
        variant="outlined"
        fullWidth={true}
        required={true}
        inputRef={firstInputRef}
      />
    </StepContent>
  )
}

export default ResourceNameStep;
