import React, { useState, Fragment, useCallback } from 'react';
import { ResourceUnion, ResourceType, Cement, Excipient, Filler, Addition, Extra } from '../../types';
import { Stepper, Step, StepButton, StepLabel, Typography, DialogActions, Button, Divider, LinearProgress } from '@material-ui/core';
import ResourceNameStep from './ResourceNameStep';
import ResourceInformationStep from './ResourceInformationStep';
import ResourcePropertiesStep from './ResourcePropertiesStep';
import { StepContentProps } from '@material-ui/core/StepContent';
import ResourcePlantsStep from './ResourcePlantsStep';
import NumberFormat from 'react-number-format';
import CementPropertiesStep, { renderCementPropertiesOptionalElement } from './CementPropertiesStep';
import ExcipientPropertiesStep, { renderExcipientPropertiesOptionalElement } from './ExcipientPropertiesStep';
import ExtraPropertiesStep, { renderExtraPropertiesOptionalElement } from './ExtraPropertiesStep';
import AdditionPropertiesStep, { renderAdditionPropertiesOptionalElement } from './AdditionPropertiesStep';
import SieveTestStep, { renderSieveTestOptionalElement } from './SieveTestStep';
import FillerPropertiesStep from './FillerPropertiesStep';

const numberFormatProps = {
  displayType: 'text' as 'text',
  decimalSeparator: ',',
  thousandSeparator: '.',
  decimalScale: 1
}

const renderResourceInformationOptionalElement = (resource: ResourceUnion) => <Fragment>
  {resource.supplier ? <Fragment><Typography component="span" variant="caption">Leverancier: </Typography><Typography component="span" variant="body2">{resource.supplier}</Typography></Fragment> : null}
  {resource.supplier && (resource.brand || resource.articleCode || resource.price) ? <span>, </span> : null}
  {resource.brand ? <Fragment><Typography component="span" variant="caption">Merk: </Typography><Typography component="span" variant="body2">{resource.brand}</Typography></Fragment> : null}
  {(resource.supplier || resource.brand) && (resource.articleCode || resource.price) ? <span>, </span> : null}
  {resource.articleCode ? <Fragment><Typography component="span" variant="caption">Artikel code: </Typography><Typography component="span" variant="body2">{resource.articleCode}</Typography></Fragment> : null}
  {(resource.supplier || resource.brand || resource.articleCode) && resource.price ? <span>, </span> : null}
  {resource.price ? <Fragment><Typography component="span" variant="caption">Prijs: </Typography><Typography component="span" variant="body2">&euro; <NumberFormat value={resource.price} {...numberFormatProps} decimalScale={2} fixedDecimalScale={true} /></Typography></Fragment> : null}
</Fragment>;

const renderResourcePropertiesOptionalElement = (resource: ResourceUnion) => <Fragment>
  {resource.density ? <Fragment><Typography component="span" variant="caption">Volumieke massa: </Typography><Typography component="span" variant="body2"><NumberFormat value={resource.density} {...numberFormatProps} /> kg/m<sup>3</sup></Typography></Fragment> : null}
  {resource.density && (resource.chloridePercentage || resource.alkaliPercentage) ? <span>, </span> : null}
  {resource.chloridePercentage ? <Fragment><Typography component="span" variant="caption">Chloridegehalte: </Typography><Typography component="span" variant="body2"><NumberFormat value={resource.chloridePercentage} {...numberFormatProps} decimalScale={3} />%</Typography></Fragment> : null}
  {(resource.density || resource.chloridePercentage) && resource.alkaliPercentage ? <span>, </span> : null}
  {resource.alkaliPercentage ? <Fragment><Typography component="span" variant="caption">Alkali gehalte: </Typography><Typography component="span" variant="body2"><NumberFormat value={resource.alkaliPercentage} {...numberFormatProps} decimalScale={3} />%</Typography></Fragment> : null}
  {resource.cvalue ? <Fragment><Typography component="span" variant="caption">C-waarde: </Typography><Typography component="span" variant="body2"><NumberFormat value={resource.cvalue} {...numberFormatProps} decimalScale={2} /></Typography></Fragment> : null}
</Fragment>;

export type StepContentComponent<T extends ResourceUnion> = React.FC<{
  onChange: (resource: ResourceUnion) => void,
  onSave?: () => void,
  onCancel?: () => void,
  resources?: ResourceUnion[],
  resource: T,
  active?: boolean,
} & Omit<StepContentProps, 'onChange' | 'resource' | 'children'>>

type TStep<T extends ResourceUnion> = {
  label: string
  optional: (resource: T) => string | number | null | JSX.Element,
  completed: (resource: T, resources?: ResourceUnion[]) => boolean,
  error?: (resource: T) => boolean,
  StepContentComponent: StepContentComponent<T>
}

const steps: TStep<ResourceUnion>[] = [
  {
    label: 'Grondstof naam',
    optional: (resource: ResourceUnion) => resource.name ? resource.name : null,
    completed: (resource: ResourceUnion) => Boolean(resource.name),
    StepContentComponent: ResourceNameStep
  },
  {
    label: 'Grondstof informatie',
    optional: renderResourceInformationOptionalElement,
    completed: (resource: ResourceUnion) =>
        Boolean(resource.price) && Boolean(resource.articleCode),
    StepContentComponent: ResourceInformationStep
  },
  {
    label: 'Grondstof eigenschappen',
    optional: renderResourcePropertiesOptionalElement,
    completed: (resource: ResourceUnion) => Boolean(resource.density),
    StepContentComponent: ResourcePropertiesStep
  },
  {
    label: 'Beton centrale(s)',
    optional: (resource: ResourceUnion) => resource.plants.length > 0 ? resource.plants.map(p => p.name).join(', ') : null,
    completed: (resource: ResourceUnion) => resource.plants.length > 0,
    StepContentComponent: ResourcePlantsStep
  }
];

function resourceTypeSteps(resource: ResourceUnion) {
  switch (resource.type) {
    case ResourceType.Cement:
      return [
        {
          label: 'Cement eigenschappen',
          optional: renderCementPropertiesOptionalElement,
          completed: (resource: Cement) => Boolean(resource.strengthNorm),
          StepContentComponent: CementPropertiesStep
        } as TStep<Cement>
      ];
    case ResourceType.Filler:
      return [
        {
          label: 'Vulstof eigenschappen',
          optional: () => '',
          completed: (resource: Filler) => Boolean(resource.cementKFactors),
          StepContentComponent: FillerPropertiesStep
        } as TStep<Filler>
      ];
    case ResourceType.Excipient:
      return [
        {
          label: 'Hulpstof eigenschappen',
          optional: renderExcipientPropertiesOptionalElement,
          completed: (resource: Excipient) => resource.mainExcipientEffectId>0,
          StepContentComponent: ExcipientPropertiesStep
        } as TStep<Excipient>
      ];
    case ResourceType.Extra:
      return [
        {
          label: 'Extra eigenschappen',
          optional: renderExtraPropertiesOptionalElement,
          completed: (resource: Extra) => true,
          StepContentComponent: ExtraPropertiesStep
        } as TStep<Extra>
      ];
    case ResourceType.Addition:
      return [
        {
          label: 'Toeslag eigenschappen',
          optional: renderAdditionPropertiesOptionalElement,
          completed: (resource: Addition) => true,
          StepContentComponent: AdditionPropertiesStep
        } as TStep<Addition>,
        {
          label: 'Zeefanalyse',
          optional: renderSieveTestOptionalElement,
          completed: (resource: Addition) => Boolean(resource.sieveTest),
          StepContentComponent: SieveTestStep
        } as TStep<Addition>
      ];
    default: return [] as TStep<ResourceUnion>[];
  }
}

type AddResourceStepsProps = {
  resources: ResourceUnion[],
  resource: ResourceUnion,
  onChange: (resource: ResourceUnion) => void,
  onSave: () => Promise<void>,
  onCancel: () => void
}

const AddResourceSteps: React.FC<AddResourceStepsProps> = ({ resources, resource, onChange, onSave, onCancel }) => {
  const [saving, setSaving] = useState(false)
  const [step, setStep] = useState(0);
  const composedSteps = [...steps, ...resourceTypeSteps(resource)];
  const { completed } = composedSteps[step];
  const editing = Boolean(resource.id);
  const valid = composedSteps.reduce((valid, step) =>
    !valid ? false : (step.completed(resource as any) && isUnique()), true);

  function isUnique() {
    if(!resources) return true;
    if(!resource.articleCode) return true;

    let code=resource.articleCode.trim();
    if(!code) return true;

    for(let r of resources) {
      if(r.articleCode===null)
        continue;
      if(r.articleCode.toUpperCase()===code.toUpperCase() && r.id!==resource.id) {
        return false;
      }
    }

    return true;
  }

  const handleSave = useCallback(async () => {
    setSaving(true)
    await onSave()
    setSaving(false)
  }, [onSave])

  return (
    <Fragment>
      <div style={{ flex: 1, overflow: 'auto' }}>
        <Stepper activeStep={step} nonLinear={true} orientation="vertical">
          {composedSteps.map(({ label, optional, completed, error, StepContentComponent }:any, k) => (
            <Step key={k} {...editing && { active: true }}>
              <StepButton onClick={() => !editing && setStep(k)} completed={completed(resource)} disabled={k > step && !completed(resource)} optional={k !== step && !editing && optional(resource)}>
                <StepLabel style={{ textAlign: 'left' }} error={step >= k && error && error(resource)}>{label}</StepLabel>
              </StepButton>
              <StepContentComponent onChange={onChange} resources={resources} resource={resource} onSave={onSave} />
            </Step>
          ))}
        </Stepper>
      </div>
      <Divider />
      <DialogActions style={{ position: 'relative' }}>
        {!editing && <Button onClick={() => setStep(step - 1)} disabled={step === 0}>Vorige</Button>}
        {!editing && <Button onClick={() => setStep(step + 1)} disabled={!completed(resource as any) || step === composedSteps.length - 1}>Volgende</Button>}
        <span style={{ flex: 1 }} />
        <Button onClick={() => onCancel()} color="secondary">Annuleren</Button>
        <Button id={'save_resource'} onClick={handleSave} disabled={!valid || saving} color="primary">Opslaan</Button>
        {saving && <LinearProgress variant="indeterminate" style={{ position: 'absolute', top: 0, left: -8, width: '100%', marginRight: -8 }} />}
      </DialogActions>
    </Fragment>
  )
}

export default AddResourceSteps;
