import React, { useState, useContext, useCallback, useEffect } from 'react';
import { Typography, AppBar, Toolbar, IconButton, Icon, Button, Menu, MenuItem, ListItemIcon, Tabs, Tab, makeStyles, Grid, ButtonBase, Box, Popover } from '@material-ui/core';
import { AppContext } from '../App';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import {Settings} from "../config/settings";

const apps = [
  { href: Settings.URLs.labApp, title: 'Lab', icon: require('../images/lab-icon.png').default },
  { href: Settings.URLs.planningApp.root, title: 'Orders', icon: <Icon fontSize="large">assignment</Icon>, disabled: true }
]

const useStyles = makeStyles({
  indicator: {
    background: '#fff'
  }
})

type AppToolbarProps = RouteComponentProps & {
  user: { name: string }
}

const AppToolbar: React.FC<AppToolbarProps> = ({ user, history, location }) => {
  const { logout } = useContext(AppContext);
  const [appsAnchorEl, setAppsAnchorEl] = useState(null as null | HTMLElement);
  const [menuAnchorEl, setMenuAnchorEl] = useState(null as null | HTMLElement);
  const [tab, setTab] = useState('/recipe');

  const classes = useStyles();

  useEffect(() => {
    const tab = location.pathname.split('/').slice(1).shift();
    setTab('/' + tab);
  }, [location]);

  const handleLogout = useCallback(() => {
    logout();
    setMenuAnchorEl(null);
  }, [logout]);

  const handleMenuItemClick = useCallback((path: string) => {
    history.push(path);
    setMenuAnchorEl(null);
  }, [history]);

  const handleTabChange = useCallback((e, path: string) => {
    history.push(path);
  }, [history]);

  return (
    <AppBar color="primary">
      <Toolbar>
        <Typography variant="h5">Receptuur</Typography>
        <Tabs value={(tab === '/recipe' || tab === '/resource' || tab === '/attest') ? tab : false} onChange={handleTabChange} classes={classes}>
          <Tab label="Recepten" value="/recipe" style={{ height: 64 }} />
          <Tab label="Grondstoffen" value="/resource" style={{ height: 64 }} />
        </Tabs>
        <span style={{ flex: 1 }} />
        <IconButton onClick={e => setAppsAnchorEl(e.currentTarget)} color="inherit">
          <Icon>apps</Icon>
        </IconButton>
        <Popover anchorEl={appsAnchorEl} anchorOrigin={{ vertical: 'top', horizontal: 'right' }} transformOrigin={{ vertical: 'bottom', horizontal: 'right' }} open={Boolean(appsAnchorEl)} onClose={() => setAppsAnchorEl(null)} onEntered={(ref: HTMLElement) => ref.removeAttribute('tabindex')}>
          <Grid container={true} spacing={3} style={{ margin: 16, maxWidth: 200, width: 145 }} justifyContent="center" wrap="wrap">
            {apps.map(({ title, href, icon }, k) => (
              <Grid item={true} key={k}>
                <ButtonBase onClick={() => (window.location.href = href)} >
                  {/* @ts-ignore */}
                  <Box display="flex" flexDirection="column" alignItems="center">
                    {typeof icon === 'string' ? <img src={icon} alt={title} width={36} /> : icon}
                    <Typography variant="subtitle2">{title}</Typography>
                  </Box>
                </ButtonBase>
              </Grid>
            ))}
          </Grid>
        </Popover>
        <Button onClick={(e) => setMenuAnchorEl(e.currentTarget)} color="inherit">
          <Icon>account_circle</Icon>&nbsp;&nbsp;
          {user.name}
        </Button>
        <Menu anchorEl={menuAnchorEl} open={Boolean(menuAnchorEl)} disableAutoFocusItem={true} onClose={() => setMenuAnchorEl(null)} onEntered={(ref: HTMLElement) => ref.removeAttribute('tabindex')}>
          <MenuItem onClick={() => handleMenuItemClick('/settings')} selected={tab === '/settings'}>
            <ListItemIcon><Icon>settings</Icon></ListItemIcon> Instellingen
              </MenuItem>
          <MenuItem onClick={handleLogout}>
            <ListItemIcon><Icon>power_settings_new</Icon></ListItemIcon> Uitloggen
              </MenuItem>
        </Menu>
      </Toolbar>
    </AppBar>
  );
}

export default withRouter(AppToolbar);
