import React, { useState, useEffect, useCallback } from 'react';
import { Dialog, Slide, DialogTitle, Avatar, Divider } from '@material-ui/core';
import AddResourceSteps from './AddResourceSteps';
import resourceTypes from '../../resourceTypes';
import ConfirmDialog, { ConfirmDialogProps } from '../ConfirmDialog';
import { TransitionProps } from '@material-ui/core/transitions/transition';
import { ResourceUnion } from '../../types';

const Transition = React.forwardRef<unknown, TransitionProps>(function Transition(props: any, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

type ResourceDetailDialogProps = {
  open: boolean,
  resources: ResourceUnion[];
  resource: ResourceUnion,
  onClose: () => void
  onSave: (resource: ResourceUnion) => Promise<void>
}

const ResourceDetailDialog: React.FC<ResourceDetailDialogProps> = ({ open, onClose, onSave, ...props }) => {
  const [changed, setChanged] = useState(false);
  const [resources, setResources] = useState(props.resources);
  const [resource, setResource] = useState(props.resource);
  const [dialogProps, confirmClose] = useState({ open: false } as Omit<ConfirmDialogProps, 'title' | 'content'>);

  useEffect(() => {
    // If articlecode is not set try to default this to the resource name
    if(props.resource.articleCode===null && props.resource.name)
      props.resource.articleCode=props.resource.name;
    setResource(props.resource);
    setResources(props.resources);
  }, [open, props.resource, props.resources]);

  const handleChanged = useCallback((resource: ResourceUnion) => {
    setResource(resource);
    setChanged(true);
  }, []);

  const handleSave = useCallback(async () => {
    await onSave(resource);
  }, [onSave, resource]);

  const handleClose = useCallback(() => {
    if (changed) {
      confirmClose({
        open: true,
        onCancel: () => confirmClose({ open: false }),
        onConfirm: () => {
          onClose();
          confirmClose({ open: false });
        }
      });
    } else {
      onClose();
    }
  }, [onClose, changed]);

  return (
    <Dialog open={open} TransitionComponent={Transition} onClose={handleClose} style={{ display: 'flex', flexDirection: 'column' }} fullWidth={true} onEntered={(ref: HTMLElement) => ref.removeAttribute('tabindex')}>
      <DialogTitle color="primary"><div style={{ display: 'flex', alignItems: 'center' }}><Avatar style={{ background: resourceTypes[resource.type as keyof typeof resourceTypes].color, marginRight: 8 }}><img src={resourceTypes[resource.type as keyof typeof resourceTypes].icon} alt="" style={{ width: 24, height: 24 }} /></Avatar><span>Grondstof bewerken</span></div></DialogTitle>
      <Divider />
      <AddResourceSteps resources={resources} resource={resource} onChange={handleChanged} onSave={handleSave} onCancel={handleClose} />
      <ConfirmDialog {...dialogProps} title="Er zijn niet opgeslagen aanpassingen" content="Weet u zeker dat u wilt afsluiten zonder op te slaan?" />
    </Dialog>
  )
}

export default ResourceDetailDialog;
