import React, { Fragment, useRef, useEffect } from 'react';
import StepContent from '@material-ui/core/StepContent';
import { StepContentComponent } from './AddResourceSteps';
import { Grid, InputAdornment, Typography } from '@material-ui/core';
import { Cement } from '../../types';
import NumberFormat from 'react-number-format';
import NumericTextField from '../NumericTextField';

const numberFormatProps = {
  displayType: 'text' as 'text',
  decimalSeparator: ',',
  thousandSeparator: '.',
  decimalScale: 1
}

export const renderCementPropertiesOptionalElement = (resource: Cement) => <Fragment>
  {resource.strengthWeek ? <Fragment><Typography component="span" variant="caption">Sterkte 7 dagen: </Typography><Typography component="span" variant="body2"><NumberFormat value={resource.strengthWeek} {...numberFormatProps} /> N/mm<sup>2</sup></Typography></Fragment> : null}
  {resource.strengthWeek && resource.strengthNorm ? <span>, </span> : null}
  {resource.strengthNorm ? <Fragment><Typography component="span" variant="caption">Sterkte 28 dagen (norm): </Typography><Typography component="span" variant="body2"><NumberFormat value={resource.strengthNorm} {...numberFormatProps} /> N/mm<sup>2</sup></Typography></Fragment> : null}
</Fragment>;

const CementPropertiesStep: StepContentComponent<Cement> = ({ onChange, onSave, resource, ...stepContentProps }) => {
  const firstInputRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    !resource.id && stepContentProps.active && firstInputRef.current && firstInputRef.current.focus();
  }, [stepContentProps.active, resource.id]);
  return (
    <StepContent {...stepContentProps}>
      <Grid container={true} spacing={2}>
        <Grid item={true} lg={6}>
          <NumericTextField
            label="Sterkte 7 dagen"
            value={typeof resource.strengthWeek !== 'undefined' && resource.strengthWeek !== null ? resource.strengthWeek : ''}
            onChange={(e: any) => onChange({ ...resource, strengthWeek: e.target.value })}
            fullWidth={true}
            inputRef={firstInputRef}
            maximumFractionDigits={6}
            InputProps={{
              endAdornment: <InputAdornment position="end">N/mm<sup>2</sup></InputAdornment>
            }}
          />
        </Grid>
        <Grid item={true} lg={6}>
          <NumericTextField
            label="Sterkte 28 dagen"
            helperText="(Normwaarde)"
            value={typeof resource.strengthNorm !== 'undefined' && resource.strengthNorm !== null ? resource.strengthNorm : ''}
            onChange={(e: any) => onChange({ ...resource, strengthNorm: e.target.value })}
            fullWidth={true}
            required={true}
            maximumFractionDigits={6}
            InputProps={{
              endAdornment: <InputAdornment position="end">N/mm<sup>2</sup></InputAdornment>
            }}
          />
        </Grid>
      </Grid>
    </StepContent>
  )
}

export default CementPropertiesStep;
