import React, { useEffect, useState } from 'react'
import { TableCellProps, TableCell, List, ListItem, ListItemAvatar, Avatar, ListItemText } from '@material-ui/core'
import {getUser} from "../../../HTTPClients/PlanningApp/users";

const cachedUsers: any = {}
const AuthorTableCell: React.FC<TableCellProps & { author: string }> = ({ author, ...props }) => {
  const [user, setUser] = useState(null as { name: string, email: string, picture: string } | null)

  useEffect(() => {
    (async () => {
      if (!cachedUsers[author]) {
        // Get and show author user
        let authorId=parseInt(author);
        if(isNaN(authorId)) {
          // Old Auth0 User; Is not stored on the Recipe App Server
          cachedUsers[author]={ name: 'Auteur niet gevonden', picture: '' }
        } else
          getUser(authorId)
              .then( response =>
                cachedUsers[author]={ name: response.data.data.username, picture: ''
              })
              .catch( error => {
                cachedUsers[author]={ name: 'Auteur niet gevonden', picture: '' }
              })
              .finally(()=> {setUser(cachedUsers[author])} )
      }
    })()
  }, [author])

  return <TableCell {...props}>
    {user && <List disablePadding={true}>
      <ListItem dense={true} style={{ padding: 0 }}>
        <ListItemAvatar style={{ minWidth: 36 }}><Avatar src={user.picture} alt={user.name} style={{ width: 20, height: 20 }} /></ListItemAvatar>
        <ListItemText primary={user.name} />
      </ListItem>
    </List>}
  </TableCell>
}

export default AuthorTableCell
