import {Settings} from "../../../config/settings";
import axiosHttpClient from "../axiosHttpClient";

export async function post(data: any) {
	return axiosHttpClient.post('/recipe/'+Settings.default_plant_id, data);
}

export async function copy(id: number) {
	return axiosHttpClient.get('/recipe/copy/'+id.toString());
}

export async function publish(id: number) {
	return axiosHttpClient.get('/recipe/publish/'+id.toString());
}

export async function publishBatch(ids: number[]) {
	return axiosHttpClient.post('/recipes/publish/batch', ids);
}

export async function unPublish(id: number) {
	return axiosHttpClient.get('/recipe/unpublish/'+id.toString());
}

export async function unPublishBatch(ids: number[]) {
	return axiosHttpClient.post('/recipes/unpublish/batch', ids);
}

export async function archive(id: number) {
	return axiosHttpClient.get('/recipe/archive/'+id.toString());
}

export async function archiveBatch(ids: number[]) {
	return axiosHttpClient.post('/recipes/archive/batch', ids);
}

export async function getAll(archived: boolean=false) {
	let param='';
	if(archived!==null) {
		param=archived ? 'true' : 'false';
		param='/'+param;
	}
	return axiosHttpClient.get('/recipes'+param);
}

export async function getWithErrors() {
  return axiosHttpClient.get('/recipes/errors');
}

export async function getOne(id: number) {
	return axiosHttpClient.get('/recipe/one/'+id.toString()+'/'+Settings.default_plant_id);
}

export async function getWithResource(resourceId: number) {
	return axiosHttpClient.get('/recipes/with-resource/'+resourceId.toString()+'/'+Settings.default_plant_id);
}

export async function getWithWorktype(worktype_id: number) {
  return axiosHttpClient.get('/recipes/with-worktype/'+worktype_id.toString()+'/'+Settings.default_plant_id);
}

export async function isUnique(id: number, name: string) {
	return axiosHttpClient.post('/recipe/unique', { id: id, name: name} );
}
