import React, { Fragment, useState, useCallback } from 'react'
import {TextField, Chip, Box, MenuItem, Snackbar} from '@material-ui/core'
import ConfirmDialog, { ConfirmDialogProps } from '../ConfirmDialog'
import AddSettingDialog from './AddSettingDialog'
import SettingsTable, { Column } from './SettingsTable'
import SettingsComponent from './SettingsComponent'
import { useHistory } from 'react-router-dom'
import Autocomplete from '@material-ui/lab/Autocomplete'
import CustomSnackbarContent from "../CustomSnackbarContent";
import { getAll as getSizes} from "../../HTTPClients/RecipeApp/sieve-tests/sieve-sizes";
import { deleteAllowed, getAll, getOne, post, remove } from "../../HTTPClients/RecipeApp/sieve-tests/sieve-sets";

const types = { sand: 'Zand', gravel: 'Grind', mixture: 'Mengsel' }

const SieveSets: React.FC<{ PrevComponent: React.FC<{ SearchElement?: JSX.Element }> }> = ({ PrevComponent }) => {
  const [sieveSizes, setSieveSizes] = useState([]);
  const [dialogProps, confirmDelete] = useState({ open: false } as Omit<ConfirmDialogProps, 'title' | 'content'>)
  const [filter, setFilter] = useState('')
  const history = useHistory()
  const [snackbar, setSnackbar] = useState(undefined as { message: string, variant: 'success' | 'warning' | 'error' | 'info' } | undefined);

  const columns: Column[] = [
    { name: 'code', title: 'Code', style: { whiteSpace: 'nowrap' } },
    { name: 'description', title: 'Beschrijving' },
    { name: 'type', title: 'Type', getValue: (v: keyof typeof types) => types[v] },
    {
      name: 'sieveSteps', title: 'Zeefmaten', getValue: (sieveSteps: any[]) => {
        return <Box maxWidth={420}>
          {sieveSteps.sort((a, b) => b.sieveSize.size - a.sieveSize.size).map(({ sieveSize }, k) => <Chip
            key={k}
            label={sieveSize.code}
            size="small"
            clickable={true}
            onClick={() => history.push('/settings/sieve_sizes/edit/' + sieveSize.id)}
            variant="outlined"
            style={{ marginTop: 2, marginBottom: 2, marginRight: 4 }}
          />)}
        </Box>
      }
    }
  ]

  if(!sieveSizes.length)
    getSizes().then( function(response) { setSieveSizes(response.data.data) } )

  const getRows = useCallback(async () => {
    let response=await getAll();
    return response.data.data;
  }, [])

  const getRow = useCallback(async (sieveSetId: string) => {
    let response=await getOne(Number(sieveSetId));
    return response.data.data;
  }, [])

  const handleSave = useCallback(async (sieveSet: any, sieveSets: any[] | undefined, saveCallback: (sieveSets?: any[]) => void) => {
    if (sieveSets && sieveSet.id) {
      post(sieveSet).then(
          function(response) {
            const index = sieveSets.findIndex(f => f.id === sieveSet.id)
            index >= 0 && (sieveSets[index] = sieveSet)
            saveCallback(sieveSets);
          }
      ).catch(
          function (error) {
            if(error.response===undefined)
              console.log(error);
            else
            if(error.response.data==='not_unique') notUniqueSnackbar();
          }
      )
    } else if (sieveSets) {
      post(sieveSet).then(
          function(response) {
            sieveSets.push({ ...sieveSet, id: response.data.data.id })
            saveCallback(sieveSets);
          }
      ).catch(
          function (error) {
            if(error.response===undefined)
              console.log(error);
            else
            if(error.response.data==='not_unique') notUniqueSnackbar();
          }
      )
    }
  },[]);

  function notUniqueSnackbar() {
    setSnackbar({
      variant: 'warning',
      message: 'Deze zeefset bestaat al'
    })
  }

  const handleDelete = useCallback((sieveSet: any, deleteCallback: (sieveSet: any) => void) => {
    deleteAllowed(sieveSet.id).then(
        function(response) {
          if(response.data.allowed) {
            confirmDelete({
              open: true,
              onCancel: () => confirmDelete({ open: false }),
              onConfirm: async () => {
                remove(sieveSet.id).then(
                    function(response) {
                      deleteCallback(sieveSet);
                    }
                ).finally(
                    function() { confirmDelete({ open: false }); }
                )
              }
            });
          } else {
            noDeleteSnackbar();
            return;
          }
        }
    )
  },[]);

  function noDeleteSnackbar() {
    setSnackbar({
      variant: 'warning',
      message: 'Deze zeefset mag niet verwijderd worden'
    })
  }

  const SearchElement = <TextField placeholder="Zeefset zoeken..." value={filter} onChange={e => setFilter(e.target.value)} variant="outlined" margin="dense" />

  return (
    <SettingsComponent
      PrevComponent={PrevComponent}
      SearchElement={SearchElement}
      path="/settings/sieve_sets"
      getRows={getRows}
      getRow={getRow}
    >{({ rows, row, addDialogOpen, onEdit, onSave, onChange, onDelete, onCancelAdd }) => (
      <Fragment>
        {rows && <SettingsTable
          columns={columns}
          rows={rows.filter(f => f.code.toLowerCase().indexOf(filter.toLowerCase()) >= 0)}
          onEdit={(sieveSet: any) => sieveSet.id && onEdit(sieveSet.id)}
          onDelete={(sieveSet: any) => handleDelete(sieveSet, onDelete)}
        />}
        {!row ? null : (
          <AddSettingDialog
            title={row.id ? 'Zeefset bewerken' : 'Zeefset toevoegen'}
            open={addDialogOpen}
            onClose={() => onCancelAdd()}
            onSave={() => handleSave(row, rows, onSave)}
          >
            <TextField
              label="Zeefset code"
              value={row.code || ''}
              onChange={e => onChange({ ...row, code: e.target.value })}
              variant="outlined"
              margin="normal"
              fullWidth={true}
              required={true}
            />
            <TextField
              label="Beschrijving"
              value={row.description || ''}
              onChange={e => onChange({ ...row, description: e.target.value })}
              variant="outlined"
              margin="normal"
              fullWidth={true}
              multiline={true}
            />
            <TextField
              label="Type"
              value={row.type || ''}
              onChange={e => onChange({ ...row, type: e.target.value })}
              variant="outlined"
              margin="normal"
              fullWidth={true}
              select={true}
              required={true}
            >
              {Object.keys(types).map(type => <MenuItem key={type} value={type}>{types[type as keyof typeof types]}</MenuItem>)}
            </TextField>
            <Autocomplete
              multiple={true}
              value={row.sieveSteps ? row.sieveSteps.map(({ sieveSize }: any) => sieveSize) : []}
              filterSelectedOptions={true}
              options={sieveSizes ? sieveSizes : []}
              onChange={(e, sieveSizes) => onChange({ ...row, sieveSteps: sieveSizes.map(sieveSize => ({ sieveSize })) })}
              getOptionLabel={(option: any) => option.code || ''}
              renderInput={params => (
                <TextField
                  {...params}
                  variant="outlined"
                  margin="normal"
                  label="Zeefmaten"
                />
              )}
            />
          </AddSettingDialog>
        )}
        <ConfirmDialog {...dialogProps} title="Zeefset verwijderen" content="Weet u zeker dat u deze zeefset wilt verwijderen?" />
        <Snackbar open={Boolean(snackbar)} onClose={() => setSnackbar(undefined)} autoHideDuration={6000}>
          <CustomSnackbarContent
              variant={snackbar ? snackbar.variant : undefined}
              message={snackbar ? snackbar.message : undefined}
          />
        </Snackbar>
      </Fragment>
    )}
    </SettingsComponent>
  )
}

export default SieveSets
