import React, { useCallback, useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, Slide, LinearProgress } from '@material-ui/core';
import { TransitionProps } from '@material-ui/core/transitions/transition';

const Transition = React.forwardRef<unknown, TransitionProps>(function Transition(props:any, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export type ConfirmDialogProps = {
  open: boolean,
  title: string,
  content: string,
  onCancel?: () => void,
  onConfirm?: () => void,
  confirmText?: string,
  cancelText?: string
}

const ConfirmDialog: React.FC<ConfirmDialogProps> = (props) => {
  const { open, title, content, onCancel, onConfirm, confirmText, cancelText } = props;
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(false);
  }, [open]);

  const handleConfirm = useCallback(() => {
    setLoading(true);
    onConfirm && onConfirm();
  }, [onConfirm]);

  return (
    <Dialog
      open={open}
      disableBackdropClick={true}
      disableEscapeKeyDown={true}
      maxWidth="xs"
      TransitionComponent={Transition as any}
      onEntered={(ref: HTMLElement) => ref.removeAttribute('tabindex')}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent><Typography>{content}</Typography></DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>{cancelText ? cancelText : 'Annuleren'}</Button>
        <div style={{ display: 'flex' }}>
          <div style={{ position: 'relative' }}>
            <Button onClick={handleConfirm} disabled={loading} color="secondary">{confirmText ? confirmText : 'Ok'}</Button>
            {loading && <LinearProgress variant="indeterminate" color="secondary" style={{ background:'none', position: 'absolute', bottom: 0, left: 0, width: '100%' }} />}
          </div>
        </div>
      </DialogActions>
    </Dialog>
  )
}

export default ConfirmDialog;
