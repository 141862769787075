import React, { useCallback } from 'react'
import { ListItem, ListItemAvatar, ListItemText, Avatar } from '@material-ui/core'
import { RecipeRevision, Recipe } from '../../types'
import moment from 'moment'
import { useHistory } from 'react-router-dom'


const RevisionListItem: React.FC<{ revision: RecipeRevision & { user?: { name: string, picture: string } }, recipe: Recipe }> = ({ revision, recipe }) => {
  const { date, user } = revision
  const history = useHistory()

  const goToRevision = useCallback((recipe: Recipe, revision: string) => {
    history.push('/recipe/' + recipe.id + '/revision/' + revision)
  }, [history])

  return <ListItem button={true} onClick={() => goToRevision(recipe, revision.id + '')}>
    <ListItemAvatar>{user ? <Avatar src={user.picture} alt={user.name} /> : <Avatar></Avatar>}</ListItemAvatar>
    <ListItemText primary={`${moment(date).fromNow()} (${moment(date).format('DD MMM YYYY H:mm')})`} secondary={`${user ? 'Aangepast door ' + user.name : 'Onbekende auteur'}`} />
  </ListItem>
}

export default RevisionListItem
