import { EnvironmentClass, Ingredient, ResourceType, ResourceUnion, SieveTest, SieveSize, Addition, Recipe, Filler, Cement, Excipient, ExcipientEffect, Extra } from '../types';

export function getMinCement(environmentClasses: EnvironmentClass[]) {
  return environmentClasses.reduce((minCement, ec) => ec.minCement > minCement ? ec.minCement : minCement, 0);
}

export function getMaxWbf(environmentClasses: EnvironmentClass[]) {
  return environmentClasses.reduce((maxWbf, ec) => ec.maxWbf < maxWbf ? ec.maxWbf : maxWbf, 1);
}

export function getVolume(ingredients: Array<{ amount: number, resource: { density: number } }>) {
  return ingredients.reduce((volume, r) => volume += (r.amount ? r.amount / (r.resource.density / 1000) : 0), 0);
}

export function getSands(ingredients: Ingredient[]) {
  return ingredients.filter(i => ((i.resource as Addition).type===ResourceType.Addition && i.resource as Addition).isSand);
}

export function getGravels(ingredients: Ingredient[]) {
  return ingredients.filter(i => ((i.resource as Addition).type===ResourceType.Addition && !(i.resource as Addition).isSand));
}

export function getPercentageSand(ingredients: Ingredient[]) {
  const showConsoleLog = false;

  let additions = ingredients.filter(r => r.resource.type === ResourceType.Addition) as Array<Ingredient & {
    resource: Addition
  }>
  let additionTotal = getVolume(additions)
  let sands = additions.filter(r => r.resource.isSand)
  let sandTotal = getVolume(additions.filter(r => r.resource.isSand));
  let percentageSand=0;
  if(sands.length === 1)
    percentageSand = sands[0].percentage;
  else {
    if(additionTotal && sandTotal)
      percentageSand = Math.round(100 / additionTotal * sandTotal);
    if(percentageSand===100) percentageSand=50;
  }
  if(showConsoleLog) {
    console.log(additions);
    console.log('percentageSand: ' + percentageSand);
  }

  return percentageSand;
  /*

  let totalAdditionVolume = 0
  let totalSandVolume = 0
  let totalGravelVolume = 0

  const showConsoleLog=true;

  for (let ingredient of ingredients){
    if(ingredient.resource.type !== 'addition')
      continue;

    const volume=getVolume([ingredient])

    if(showConsoleLog)
      console.log(ingredient.resource.name+' volume: '+volume);

    if((ingredient.resource as Addition).isSand)
      totalSandVolume += volume
    else
      totalGravelVolume += volume
    totalAdditionVolume += volume;
  }

  if(showConsoleLog) {
    console.log('totalSandVolume: ' + totalSandVolume + ' totalGravelVolume: ' + totalGravelVolume + ' - totalAdditionVolume: ' + totalAdditionVolume);
    console.log('sandPercentage: '+ (totalSandVolume / totalAdditionVolume) * 100);
  }

  if(!totalAdditionVolume || totalSandVolume===100)
    return 50; // No sand or gravel, so assume 50% sand!

  return Math.round((totalSandVolume / totalAdditionVolume) * 100)

   */
}

export function getDensity(ingredients: Ingredient[]) {
  return ingredients.reduce((t, r) => t += r.amount, 0)
}

export function getKFactor(resource: Filler, cements: Ingredient[]) {
  const cementTotal = cements.reduce((total, c) => total += c.amount, 0);
  return resource.cementKFactors.reduce((kFactor, ckf) => {
    const cement = cements.find(c => c.resource.id === ckf.cement.id);
    if (cement) {
      kFactor += ckf.kFactor * 1 / cementTotal * cement.amount;
    }
    return kFactor;
  }, 0);
}

export function getBinderTotal(ingredients: Ingredient[], hasAttest: boolean) {
  return ingredients.reduce((total, r) => {
    if (r.resource.type === ResourceType.Cement) {
      total += r.amount;
    } else if (r.resource.type === ResourceType.Filler) {
      let amount = r.amount;
      if (hasAttest && r.kFactor && r.attestPercentage) {
        const cementTotal = getCementTotal(ingredients);
        const max = cementTotal * (r.attestPercentage / 100);
        if (r.amount > max) {
          amount = max;
        }
      }
      total += amount * (r.kFactor || 0);
    }
    return total;
  }, 0);
}

export function getCementTotal(ingredients: Ingredient[]) {
  return ingredients.reduce((total, r) => {
    if (r.resource.type === ResourceType.Cement) {
      total += r.amount;
    }
    return total;
  }, 0);
}

export function getChloridePercentage(ingredients: Ingredient[]) {
  const cementAmount = ingredients.filter(r => r.resource.type === ResourceType.Cement).reduce((amount, r) => amount += r.amount, 0);
  const chlorideTotal = ingredients.reduce((chloride, r) => chloride += r.amount * (r.resource.chloridePercentage || 0) / 100, 0);
  return chlorideTotal / cementAmount * 100;
}

export function getAlkaliPercentage(ingredients: Ingredient[]) {
  const cementAmount = ingredients.filter(r => r.resource.type === ResourceType.Cement).reduce((amount, r) => amount += r.amount, 0);
  const alkaliTotal = ingredients.reduce((alkali, r) => alkali += r.amount / 1000 * (r.resource.alkaliPercentage || 0), 0);
  return alkaliTotal / cementAmount * 100;
}

export function getPredictedStrength(ingredients: Ingredient[], wbf: number) {
  const cements = ingredients.filter(r => r.resource.type === ResourceType.Cement) as Array<Ingredient & { resource: Cement }>;
  const N = cements.map(c => c.percentage ? c.percentage / 100 * c.resource.strengthNorm : 0).reduce((total, value) => total + value, 0);
  const a = 0.8;
  const b = 25;
  const c = 45;
  return a * N + b / wbf - c;
}

export function getBasePrice(ingredients: Ingredient[]) {
  return ingredients.reduce((price, r) => price += ((r.amount + getMoisture([r])) / 1000) * (r.resource.price || 0), 0);
}

export function getAdditionsRatio(ingredients: Ingredient[]) {
  const additions = ingredients.filter(r => r.resource.type === ResourceType.Addition) as Array<Ingredient & { resource: ResourceUnion & { addition: { isSand: boolean } } }>;
  const total = getVolume(additions);
  const sand = getVolume(additions.filter(a => a.resource.addition.isSand));
  return [100 / total * sand, 100 - 100 / total * sand];
}

export function getAirPercentageMinMax(recipe: Recipe) {
  const { ingredients, environmentClasses } = recipe;
  const hasAirBubbles = ingredients.find(r => r.resource.type === ResourceType.Excipient && (r.resource as Excipient).mainExcipientEffectId === ExcipientEffect.AirBubbleMaker);
  const environmentClass = (environmentClasses ? environmentClasses : []).find(ec => ec.minimumAirPercentages);
  if (hasAirBubbles && environmentClass) {
    const largestGrain = getLargestGrain(ingredients.filter(r => r.resource.type === ResourceType.Addition) as any);
    const found = environmentClass.minimumAirPercentages ? environmentClass.minimumAirPercentages.find(m => m.largestGrain === largestGrain) : null
    const min = found ? found.airPercentage : 2
    return { min }
  } else {
    return { max: 2 }
  }
}

export function getPercentageFine(ingredients: Ingredient[], recipe?: Recipe) {
  let percentageFine = ingredients.reduce((percentageFine, ingredient) => {
    if ([ResourceType.Cement, ResourceType.Filler].indexOf(ingredient.resource.type as ResourceType) >= 0) {
      percentageFine += getVolume([ingredient]);
    }
    if (ingredient.resource.type === ResourceType.Addition && (ingredient.resource as Addition).sieveTest) {
      const sieveTest250 = (ingredient.resource as Addition).sieveTest.sieveSteps.find(option => option.sieveSize.size === 0.125);
      if (sieveTest250) {
        percentageFine += getVolume([ingredient]) * getCummulativeRestPercentage((ingredient.resource as Addition).sieveTest as SieveTest, sieveTest250.sieveSize) / 100;
      }
    }
    if (ingredient.resource.type === ResourceType.Extra) {
      percentageFine += getVolume([ingredient]) * (ingredient.resource as Extra).percentageFine / 100;
    }
    return percentageFine;
  }, 0);
  if (recipe) {
    const hasAirBubbles = ingredients.find(ingredient => ingredient.resource.type === ResourceType.Excipient && (ingredient.resource as Excipient).mainExcipientEffectId === ExcipientEffect.AirBubbleMaker);
    hasAirBubbles && recipe.airPercentage && (percentageFine += recipe.airPercentage * 10 - 20);
  }
  return percentageFine;
}

const sieveSizes = [0.125, 0.250, 0.500, 1, 2, 4, 8, 16, 31.5, 63];

export function getFinenessModulus(restPercentages: Array<{ sieveSize: { size: number }, percentage: number }>) {
  let cumulative = 0;
  restPercentages.filter(option => sieveSizes.indexOf(option.sieveSize.size) >= 0).forEach(({ percentage }) => {
    cumulative += percentage;
  });
  return cumulative / 100;
}

export function setDMax(additions: Array<Ingredient & { resource: Addition }>) {
  for(let addition of additions) {
    if(!addition.resource.hasOwnProperty('gradingCurve') || addition.resource.gradingCurve===null)
      continue; // Shittie but it happens!
    addition.resource.gradingCurve.dMax = getDMax([addition]);
  }
  return additions;
}

export function getDMax(additions: Array<Ingredient & { resource: Addition }>) {
  let dMax=0;
  for(let addition of additions) {
    if(!addition.resource.sieveTest) continue;
    for(let sieveStep of addition.resource.sieveTest.sieveSteps) {
      if(!sieveStep.restWeight) continue;
      if(sieveStep.sieveSize.size<=8 && dMax<8) dMax=8;
      if(sieveStep.sieveSize.size>8 && sieveStep.sieveSize.size<=16 && dMax<16) dMax=16;
      if(sieveStep.sieveSize.size>16 && sieveStep.sieveSize.size<=22.4 && dMax<22) dMax=22;
      if(sieveStep.sieveSize.size>22.4) dMax=32;
    }
  }
  return dMax;
}

export function getLargestGrain(additions: Array<Ingredient & { resource: Addition }>) {
  additions=setDMax(additions);
  return additions.filter(a => a.resource.gradingCurve).map(a => a.resource.gradingCurve.dMax || 0).sort((a, b) => a - b).pop();
}

export function getMixtureCummulativeRestPercentages(additions: Array<{ amount: number, resource: { density: number }, restPercentages: Array<{ sieveSize: { code?: string | null, size: number }, percentage: number, inBetweenPercentage?: boolean }> }>) {
  const total = getVolume(additions)
  const percentages: Array<{ sieveSize: { code?: string | null, size: number }, percentage: number, inBetweenPercentage?: boolean }> = []

  // Collect all sievesize occurrences in the addition based percentages lists
  let sieveSizes=[];
  for(let addition of additions) {
    for (let restPercentage of addition.restPercentages) {
      let found = false;
      for (let sieveSize of sieveSizes)
        if (sieveSize.id === (restPercentage.sieveSize as any).id) {
          found = true;
          break;
        }
      if (!found)
        sieveSizes.push(restPercentage.sieveSize);
    }
  }
  sieveSizes.sort((a,b) => a.size - b.size);

  // Insert default sievesizes with an average 'between-in' percentage where missing in the percentages lists
  additions.forEach(({ restPercentages, ...a }) => {
    let sieveSizePointer=0;
    let percentagePointer=0;
    for(let sieveSize of sieveSizes) {
      const index=restPercentages.findIndex(rp => (rp.sieveSize as any).id===sieveSize.id)
      if(index>-1) {
        percentagePointer=index;
        continue;
      }
      let percentage=calcAverageBetweenSizes(restPercentages, percentagePointer);
      restPercentages.splice(percentagePointer,0,{ sieveSize: sieveSize, percentage: percentage, inBetweenPercentage: true } )
      sieveSizePointer++;
    }
  })

  function calcAverageBetweenSizes(restPercentages, percentagePointer): number {
    if(restPercentages[percentagePointer]===undefined || !restPercentages[percentagePointer].percentage)
      return 0;
    let lower=percentagePointer===0 ? 0 : restPercentages[percentagePointer-1].percentage;
    let higher=percentagePointer===restPercentages.length-1 ? 100 : restPercentages[percentagePointer+1].percentage;
    return (lower+higher) / 2;
  }

  additions.forEach(({ restPercentages, ...a }) => {
    const volume = getVolume([a])
    const factor = (100 / total * volume) / 100
    restPercentages.forEach(({ sieveSize, percentage }) => {
      let cummulative = percentage * factor

      const index = percentages.findIndex(p => p.sieveSize.size === sieveSize.size)
      if (index >= 0) {
        percentages[index].percentage += cummulative
      } else {
        percentages.push({sieveSize, percentage: cummulative})
      }
    })
  })
  return percentages
}

export function removeFirstInBetweenPercentages(restPercentages) {
  const index=restPercentages.findIndex(p => !p.hasOwnProperty('inBetweenPercentage'));
  if(index >= 0) {
    for(let i=0; i<index; i++) {
      restPercentages[i].percentage=0;
      delete restPercentages[i].inBetweenPercentage;
    }
  }
}

export function getCummulativeRestPercentage(sieveTest: SieveTest, sieveSize: SieveSize) {
  const { startingWeight, sieveSteps } = sieveTest;
  let cummulative = 0;
  sieveSteps.sort((a, b) => a.sieveSize.size - b.sieveSize.size).forEach(s => {
    if (s.sieveSize.size <= sieveSize.size) {
      cummulative += 100 / startingWeight * s.restWeight;
    }
  });
  return cummulative;
}

export function getCummulativeRestPercentages(sieveTest: { startingWeight: number, sieveSteps: Array<{ sieveSize: { size: number, code?: string | null }, restWeight: number }> }) {
  const percentages: Array<{ sieveSize: { size: number, code?: string | null }, percentage: number }> = []
  let cummulative = 100
  const { startingWeight, sieveSteps } = sieveTest
  sieveSteps.sort((a, b) => b.sieveSize.size - a.sieveSize.size).forEach(({ restWeight, sieveSize }) => {
    cummulative -= 100 / startingWeight * restWeight
    if(cummulative<0) cummulative=0;
    percentages.push({ sieveSize, percentage: 100 - cummulative })
  });
  return percentages
}

export function getMoisture(ingredients: Ingredient[]) {
  return ingredients.reduce((moisture, r) => moisture += r.amount * (r.moisture || (r.resource.type === ResourceType.Addition && (r.resource as Addition).moisture) || (r.resource.type === ResourceType.Excipient && (r.resource as Excipient).moisture) || (r.resource.type === ResourceType.Extra && (r.resource as Extra).moisture) || 0) / 100, 0);
}

export function getAbsorption(ingredients: Ingredient[]) {
  return ingredients.reduce((absorption, r) => absorption += r.amount * (r.absorption || (r.resource.type === ResourceType.Addition && (r.resource as Addition).absorption) || (r.resource.type === ResourceType.Excipient && (r.resource as Excipient).absorption) || (r.resource.type === ResourceType.Extra && (r.resource as Extra).absorption) || 0) / 100, 0);
}

export function updateRecipe(recipe: Recipe, resources: ResourceUnion[]) {
  const { ingredients } = recipe

  resources.forEach(r => {
    const index = ingredients.findIndex(i => i.resource.id === r.id)
    if(index>-1)
      ingredients[index].resource = r
  })

  const water = ingredients.find(r => r.resource.type === ResourceType.Water)

  updateAdditionAmounts(resources, ingredients, recipe.airPercentage)

  resources.filter(r => r.type === ResourceType.Filler).forEach(resource => {
    updateKFactor(resource as Filler, ingredients)
  })

  recipe.binderTotal=getBinderTotal(recipe.ingredients, Boolean(recipe.attest));

  water && (recipe.wbf = water.amount / recipe.binderTotal)

  // minder belangrijk:
  // TODO: calculate vocht incl. absorptie
  // TODO: calculate absorptie
  // TODO: calculate verschil
  // TODO: calculate te doseren water

  return { ...recipe, ingredients }
}

function updateKFactor(resource: Filler, ingredients: Ingredient[]) {
  const index = ingredients.findIndex(r => r.resource.id === resource.id)
  const kFactor = getKFactor(resource, ingredients)
  ingredients[index].kFactor = kFactor
}

export function updateAdditionAmounts(resources: ResourceUnion[], oldIngredients: Ingredient[], airPercentage: number) {
  const additions = oldIngredients.filter(r => r.resource.type === ResourceType.Addition)
  const ingredients = oldIngredients.map(ingredient => {
    const resource = resources.find(r => r.id === ingredient.resource.id)
    return { ...ingredient, resource: resource ? resource : ingredient.resource }
  })
  const newAdditionVolume = 1000 - (getVolume(ingredients.filter(r => r.resource.type !== ResourceType.Addition)) + (airPercentage * 10))
  const oldAdditionVolume = getVolume(additions)

  if (newAdditionVolume !== oldAdditionVolume) {
    ingredients.forEach((r, k) => {
      if (r.resource.type === ResourceType.Addition) {
        const addition = additions.find(a => a.resource.id === r.resource.id)
        if (addition) {
          const v = newAdditionVolume * addition.percentage
          ingredients[k].amount = getAmount(v, r.resource.density)
        }
      }
    })
  }
}

export function getAmount(volume: number, density: number) {
  return Math.round(volume * density / 1000)
}

export function getPercentage(totalVolume: number, volume: number) {
  return 1 / totalVolume * volume
}

export function getErrors(recipe: Recipe) {
  const constraints: any = getRecipeConstraints(recipe);
  const errors: string[] = []
  Object.keys(constraints).forEach(key => {
    const { value, min, max } = constraints[key];
    if ((min && value < min) || (max && value > max)) {
      errors.push(key);
    }
  });
  return errors;
}

export function getEmptyIngredients(recipe: Recipe): Ingredient[] {
  const ingredients: Ingredient[] = recipe.ingredients;
  return ingredients.filter((i=>{return i.amount===0}))
}

export function getRecipeConstraints(recipe: Recipe) {
  const largestGrain = getLargestGrain(recipe.ingredients.filter(r => r.resource.type === ResourceType.Addition) as any)
  const minPercentageFine = recipe.typeOfWork && recipe.typeOfWork.minPercentageFine ? recipe.typeOfWork.minPercentageFine : 0
  const finePercentages = { 8: 140, 11: 130, 16: 125, 22: 120, 32: 115 }
  let requiredPercentageFine =
      (largestGrain && finePercentages[largestGrain as keyof typeof finePercentages]) ?
       finePercentages[largestGrain as keyof typeof finePercentages] : 160
  if (minPercentageFine > requiredPercentageFine) {
    requiredPercentageFine = minPercentageFine
  }

  const maxWBF=getMaxWbf(recipe.environmentClasses);

  return {
    maxWBF: {
      max: maxWBF,
      value: recipe.wbf
    },
    binderTotal: {
      min: getMinCement(recipe.environmentClasses || []),
      value: getBinderTotal(recipe.ingredients, Boolean(recipe.attest))
    },
    percentageFine: {
      min: requiredPercentageFine,
      value: getPercentageFine(recipe.ingredients)
    },
    predictedStrength: {
      min: recipe.strengthClass ? Math.round(recipe.strengthClass.cilinderPressureStrength * (2 / 3)) : 0,
      value: getPredictedStrength(recipe.ingredients, recipe.wbf || 0)
    },
    chloridePercentage: {
      max: recipe.chlorideClass ? recipe.chlorideClass.maxChloride : 1,
      value: getChloridePercentage(recipe.ingredients)
    },
    alkaliPercentage: {
      max: 1, // TODO: ... max 6kg???
      value: getAlkaliPercentage(recipe.ingredients)
    },
    airPercentage: {
      ...getAirPercentageMinMax(recipe),
      value: recipe.airPercentage
    }
  }
}

export function getComputations(recipe: Recipe) {
  return {
    binderTotal: Math.round(getBinderTotal(recipe.ingredients, Boolean(recipe.attest)) * 10) / 10,
    wbf: recipe.wbf ? Math.round(recipe.wbf * 1000) / 1000 : undefined,
    density: Math.round(getDensity(recipe.ingredients) * 10) / 10,
    volume: Math.round(recipe.ingredients.reduce((v, r) => v += getVolume([r]), 0) * 10) / 10 + (recipe.airPercentage * 10),
    chloridePercentage: Math.round(getChloridePercentage(recipe.ingredients) * 1000) / 1000,
    alkaliPercentage: Math.round(getAlkaliPercentage(recipe.ingredients) * 1000) / 1000,
    basePrice: Math.round(getBasePrice(recipe.ingredients) * 100) / 100,
    percentageFine: Math.round(getPercentageFine(recipe.ingredients, recipe) * 10) / 10,
    predictedStrength: recipe.wbf ? Math.round(getPredictedStrength(recipe.ingredients, recipe.wbf) * 10) / 10 : undefined
  }
}
