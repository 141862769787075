import React, {useEffect, useRef} from 'react';
import StepContent from '@material-ui/core/StepContent';
import {StepContentComponent} from './AddResourceSteps';
import {Grid, InputAdornment} from '@material-ui/core';
import NumericTextField from '../NumericTextField';
import {ResourceType, ResourceUnion} from '../../types';

const ResourcePropertiesStep: StepContentComponent<ResourceUnion> = ({ onChange, onSave, resource, ...stepContentProps }) => {
  const firstInputRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    !resource.id && stepContentProps.active && firstInputRef.current && firstInputRef.current.focus();
  }, [stepContentProps.active, resource.id]);
  return (
    <StepContent {...stepContentProps}>
      <Grid container={true} spacing={2}>
        <Grid item={true} xs={12}>
          <NumericTextField
            label="Volumieke massa"
            value={resource.density}
            onChange={(e: any) => onChange({ ...resource, density: e.target.value })}
            fullWidth={true}
            required={true}
            inputRef={firstInputRef}
            InputProps={{
              endAdornment: <InputAdornment position="end">kg/m<sup>3</sup></InputAdornment>,
            }}
          />
        </Grid>
        <Grid item={true} lg={6}>
          <NumericTextField
            label="Chloridegehalte"
            value={typeof resource.chloridePercentage !== 'undefined' && resource.chloridePercentage !== null ? resource.chloridePercentage : ''}
            onChange={(e: any) => onChange({ ...resource, chloridePercentage: e.target.value })}
            fullWidth={true}
            maximumFractionDigits={6}
          />
        </Grid>
        <Grid item={true} lg={6}>
          <NumericTextField
            label="Alkali gehalte"
            value={typeof resource.alkaliPercentage !== 'undefined' && resource.alkaliPercentage !== null ? resource.alkaliPercentage : ''}
            onChange={(e: any) => onChange({ ...resource, alkaliPercentage: e.target.value })}
            fullWidth={true}
            maximumFractionDigits={6}
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
            }}
          />
        </Grid>
        {resource.type===ResourceType.Cement && <Grid item={true} xs={12}>
          <NumericTextField
              label="C-waarde"
              value={resource.cvalue}
              onChange={(e: any) => onChange({ ...resource, cvalue: e.target.value })}
              fullWidth={true}
              maximumFractionDigits={2}
              required={false}
          />
        </Grid>}
      </Grid>
    </StepContent>
  )
}

export default ResourcePropertiesStep;
