export const Settings = {
  environment: 'development', // 'development' or 'production'
  company: 'testcentraal',
  URLs: {
    client: '',
    server: 'http://test.jbmsoftware.nl:4000',
    planningApp: {
      root: 'http://test.jbmsoftware.nl:5123/',
      recipeApp: {
        root: 'http://test.jbmsoftware.nl:5123/recipe-app/',
        loginSegment: 'post/login'
      }
    },
    labApp: '',
  }
}
