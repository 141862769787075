import React, {createContext, useState, useEffect, ReactNode} from 'react';
import Loading from './components/Loading';
import {getAll as getAttests} from "./HTTPClients/RecipeApp/attests";
import {getAll as getGradingCurves} from "./HTTPClients/RecipeApp/grading-curves";
import {getAll as getTypesOfWork} from "./HTTPClients/RecipeApp/types-of-work";
import {getAll as getStrengthClasses} from "./HTTPClients/RecipeApp/recipes/strength-classes";
import {getAll as getEnvironmentClasses} from "./HTTPClients/RecipeApp/recipes/environment-classes";
import {getAll as getConsistencyClasses} from "./HTTPClients/RecipeApp/recipes/consistency-classes";
import {getAll as getChlorideClasses} from "./HTTPClients/RecipeApp/recipes/chloride-classes";

type TStoreContextValue = {
  strengthClasses: any[],
  environmentClasses: any[],
  consistencyClasses: any[],
  typeOfWorks: any[],
  chlorideClasses: any[],
  attests: any[],
  gradingCurves: any[]
}

export const StoreContext = createContext({} as TStoreContextValue);

type Props = { children: ReactNode }

const StoreContextProvider: React.FC<Props> = ({ children } ) => {
  const [loaded, setLoaded] = useState(false);
  const [stores, setStores] = useState({
    strengthClasses: [] as any[],
    environmentClasses: [] as any[],
    consistencyClasses: [] as any[],
    typeOfWorks: [] as any[],
    chlorideClasses: [] as any[],
    attests: [] as any[],
    gradingCurves: [] as any[]
  });

  useEffect(() => {
    if(!loaded) {
      (async () => {
        let response=await getAttests();
        const attests = response.data.data;
        response=await getGradingCurves();
        const gradingCurves=response.data.data;
        response=await getTypesOfWork();
        const typeOfWorks=response.data.data;
        response=await getStrengthClasses();
        const strengthClasses=response.data.data;
        response=await getConsistencyClasses();
        const consistencyClasses=response.data.data;
        response=await getEnvironmentClasses();
        const environmentClasses=response.data.data;
        response=await getChlorideClasses();
        const chlorideClasses=response.data.data;

        setStores( {
          attests: attests,
          gradingCurves: gradingCurves,
          typeOfWorks: typeOfWorks,
          strengthClasses: strengthClasses,
          consistencyClasses: consistencyClasses,
          environmentClasses: environmentClasses,
          chlorideClasses: chlorideClasses
        });

        setLoaded(true);
      })();
    }
  }, [loaded]);

  return (
    <StoreContext.Provider value={stores as TStoreContextValue}>
      {!loaded ? <Loading text="Gegevens laden..." /> : children}
    </StoreContext.Provider>
  );
}

export default StoreContextProvider;
