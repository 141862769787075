import React, { useState, useEffect, useCallback, Fragment } from 'react';
import { Dialog, Slide, DialogContent, DialogTitle, Divider, DialogActions, Button, Avatar } from '@material-ui/core';
import { ResourceUnion, ResourceType } from '../../types';
import resourceTypes from '../../resourceTypes';
import { grey } from '@material-ui/core/colors';
import ChoseResourceType from './ChoseResourceType';
import AddResourceSteps from './AddResourceSteps';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import ConfirmDialog, { ConfirmDialogProps } from '../ConfirmDialog';
import { TransitionProps } from '@material-ui/core/transitions/transition';

const Transition = React.forwardRef<unknown, TransitionProps>(function Transition(props: any, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const initialResource: ResourceUnion = {
  plants: []
} as unknown as ResourceUnion

type AddResourceDialogProps = {
  open: boolean,
  resource?: ResourceUnion,
  resources: ResourceUnion[];
  resourceType?: ResourceType,
  onClose: () => void,
  onSave: (resource: ResourceUnion) => Promise<void>
} & RouteComponentProps

const AddResourceDialog: React.FC<AddResourceDialogProps> = ({ open, onClose, onSave, resourceType, history, ...props }) => {
  const [changed, setChanged] = useState(false);
  const [resources, setResources] = useState(props.resources);
  const [resource, setResource] = useState(props.resource || { ...initialResource, type: resourceType } as ResourceUnion);
  const [dialogProps, confirmClose] = useState({ open: false } as Omit<ConfirmDialogProps, 'title' | 'content'>);

  useEffect(() => {
    setResources(props.resources);
    setResource({ ...initialResource, type: resourceType } as ResourceUnion);
  }, [open, resourceType]);

  const handleSelectResourceType = useCallback((resourceType: ResourceType) => {
    history.push('/resource/add/' + resourceType.toLowerCase());
  }, [history]);

  const handleChanged = useCallback((resource: ResourceUnion) => {
    setResource(resource);
    setChanged(true);
  }, []);

  const handleSave = useCallback(async () => {
    await onSave(resource);
  }, [onSave, resource]);

  const handleClose = useCallback(() => {
    if (changed && resource !== initialResource) {
      confirmClose({
        open: true,
        onCancel: () => confirmClose({ open: false }),
        onConfirm: () => {
          onClose();
          confirmClose({ open: false });
        }
      });
    } else {
      onClose();
    }
  }, [onClose, changed, resource]);

  return (
    <Dialog open={open} TransitionComponent={Transition} onClose={handleClose} style={{ display: 'flex', flexDirection: 'column' }} fullWidth={true} onEntered={(ref: HTMLElement) => ref.removeAttribute('tabindex')}>
      <DialogTitle color="primary">{resource.type ? <div style={{ display: 'flex', alignItems: 'center' }}><Avatar style={{ background: resourceTypes[resource.type].color, marginRight: 8 }}><img src={resourceTypes[resource.type].icon} alt="" style={{ width: 24, height: 24 }} /></Avatar><span>{resourceTypes[resource.type].title} toevoegen</span></div> : 'Kies een grondstof type'}</DialogTitle>
      <Divider />
      {!resource.type ? (
        <Fragment>
          <DialogContent style={{ background: grey[100] }}>
            <ChoseResourceType onClick={handleSelectResourceType} />
          </DialogContent>
          <Divider />
          <DialogActions>
            <span style={{ flex: 1 }} />
            <Button onClick={handleClose} color="secondary">Annuleren</Button>
          </DialogActions>
        </Fragment>
      ) : (
          <AddResourceSteps resources={resources} resource={resource} onChange={handleChanged} onSave={handleSave} onCancel={handleClose} />
        )}
      <ConfirmDialog {...dialogProps} title="Er zijn niet opgeslagen aanpassingen" content="Weet u zeker dat u wilt afsluiten zonder op te slaan?" />
    </Dialog>
  )
}

export default withRouter(AddResourceDialog);
